import React from 'react'
import '../../assets/styles/shared/banner.css'
import {LazyLoadImage} from 'react-lazy-load-image-component';

export const Banner = ({ image, title, color }) => {
  const colorStyle = {
    color: color,
  }
  return (
    <div className='banner'>
      <div className='flex-center'>
        <div className='banner-img-container flex-center'>
          <div id='banner-overlay'></div>
          <LazyLoadImage src={image} className='banner-img' />
        </div>
        <div className='position-absolute'>
          <h1 className='banner-title' style={colorStyle}>{title}</h1>
        </div>
      </div>
    </div>
  )
}
