import React from 'react'
import instagram from '../../assets/images/asset.insta.svg';
import facebook from '../../assets/images/asset.fb.svg';
import tiktok from '../../assets/images/asset.tt.svg';
import '../../assets/styles/banners.css';

export const SocialMediaSection = () => {
  return (
    <div id='social-media-section' className='d-flex v-flex-center banner-background h-600'>
      <div className='div-center'>
        <h1 className='py-2'>Follow us on Social Media</h1>
        <div className='d-flex justify-center py-4'>
          <div className='px-3'>
            <a href='https://www.instagram.com/outdooradventurecrew/?hl=en' target='_blank'>
                <img src={instagram} className='px-2' />
            </a>
          </div>
          <div className='px-3'>
            <a href='https://www.facebook.com/outdooradventurecrew/' target='_blank'>
            <img src={facebook} className='px-2' />
            </a>
          </div>
          <div className='px-3'>
            <a href='https://www.tiktok.com/@outdooradventurecrew/live' target='_blank'>
              <img src={tiktok} className='px-2' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
