import adMap from '../../assets/images/asset.map.svg';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Link} from 'react-router-dom';
import React, {useContext} from 'react';
import NavbarContext from '../shared/navbar-context';
import {PAGES} from '../shared/navbar';

export const CalendarSection = () => {
  const {navbarContext, setNavbarContext} = useContext(NavbarContext);

  return (
    <div id='calendar-section' className='d-flex v-flex-center'>
      <div className='py-4 div-center my-4 calendar-container'>
        <div className='d-flex justify-center calendar-content-container'>
          <LazyLoadImage src={adMap} className='w-v85' />
        </div>
        <div className='w-v85 div-center py-4 calendar-content-container'>
          <h1 className='text-center'>A New Adventure Awaits!</h1>
          <p className='py-4 f-24 text-center'>Take a look at our calendar and discover your next adventure</p>
          <div className='btn text-center events-btn'>
            <Link 
              to='/events' 
              className='text-center link' 
              onClick={() => setNavbarContext(PAGES.EVENTS)}
            >
              View Calendar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}