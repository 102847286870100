import React, {useRef, useState} from 'react'
import plusIcon from '../../assets/images/asset.plus-icon.svg';
import {LazyLoadImage} from 'react-lazy-load-image-component';

export const QaBox = ({question, answers}) => {
  const [isVisible, setIsVisible] = useState(false);
  const contentRef = useRef(null);

  const toggleVisibility = () => setIsVisible(!isVisible);
  const getMaxHeight = () => isVisible ? `${contentRef.current.scrollHeight}px` : '0px';

  return (
    <div className={isVisible ? 'qa-box active' : 'qa-box'}>
      <div className='question-container'>
        <div className='d-flex center-v'>
          <p className={isVisible ? 'question active' : 'question'}>{question}</p>
        </div>
        <div className='d-flex center-v'>
          <LazyLoadImage src={plusIcon} onClick={toggleVisibility} className={isVisible ? 'view-icon rotate45' : 'view-icon'} />
        </div>
      </div>
      <div className={`answer-container ${isVisible ? 'answer-showing' : ''}`} style={{ maxHeight: getMaxHeight() }} ref={contentRef}>
        <div>
          {answers.map((answer, index) => <p key={index} className='p-2'>{answer}</p>)}
        </div>
      </div>
    </div>
  )
}
