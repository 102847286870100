import React, {useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import plusIcon from '../../assets/images/asset.plus-icon.svg';
import instagramLink from '../../assets/images/asset.instagram-vector.svg';
import emailLink from '../../assets/images/asset.email-vector.svg'
import '../../assets/styles/infoContainer.css';

// import dropDownBtn from '../../assets/images/asset.drop-down-button.svg';

export const SingleEvent = (props) => {
  const [isHidden, setHidden] = useState(true);
  const [btnStyle, setBtnStyle] = useState('inactive');
  const bodyText = `My name is _________ and I'd like to know more about the ${props.title} trip!\nSpecifically, I'd like to know more about`;
  const link = `mailto:outdooradventurecrew@gmail.com?subject=Question%20about%20${encodeURIComponent(props.title)}&amp;body=${encodeURIComponent(bodyText)}`;
  
  const styleMapping = {
    active: 'transition-2 rotate-180',
    inactive: 'transition-2'
  };

  const readInfo = () => {
    setHidden(!isHidden);

    if (btnStyle === 'active')
      setBtnStyle('inactive');
    else
      setBtnStyle('active');
  };

  const setDateRangeFull = () => {
    const { dates: { startDay, startMonth, startTime, endDay, endMonth, endTime }} = props;
    const endTimeShort = endTime ? endTime.split(' ').slice(0, 2).join(' ') : null;
    const startTimeShort = startTime ? startTime.split(' ').slice(0, 2).join(' ') : null;
    if (startDay === endDay) {
      return `${startMonth} ${startDay} ${startTimeShort}-${endTimeShort}`;
    }
    if (startMonth !== endMonth) {
      return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
    }

    return `${startMonth} ${startDay}-${endDay}`;
  };
  
  return (
    <div className='transition-2 h-auto div-center my-4 info-container'>
      <div className='br-bottom-8'>
        <div className='d-flex v-flex-center justify-between br-bottom-8 py-2 px-2 center-v event-title-container'>
          {/* <LazyLoadImage src={dropDownBtn} onClick={readInfo} className={`${styleMapping[btnStyle]} px-3 btn-hover`} /> */}
          <h2 className='p-1 px-3 event-title'>{props.title}</h2>
          <LazyLoadImage src={plusIcon} onClick={readInfo} className={!isHidden ? 'view-icon rotate45' : 'view-icon'} />
        </div>
        <div className='transition-4 ovf-hidden br-bottom-8'>
          <div className={`expand-contract px-4 br-bottom-8 ${!isHidden && 'expanded'}`}>
              <p className='px-4 py-2 disabled-text'>{setDateRangeFull()}</p>
              <p className='pb-2 px-4'>{props.description}</p>
          </div>
          <div className='px-4 d-flex justify-start center-v pb-4'>
            <p className='title-theme px-2 contact-text'>Contact for Availability</p>
            <div className='d-flex v-flex-center py-2 justify-center'>
              <div className='px-2' style={{ height: '19px' }}>
                <a href={link} target='_blank'>
                  <LazyLoadImage src={emailLink} style={{ height: '19px' }} />
                </a>
              </div>
              <div className='px-1' style={{ height: '22px' }}>
                <a href='https://www.instagram.com/outdooradventurecrew/?hl=en' target='_blank'>
                  <LazyLoadImage src={instagramLink} style={{ height: '22px' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};