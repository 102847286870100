import React, { useState } from 'react';
import Home from './pages/Home';
import People from './pages/People';
import About from './pages/About';
import FAQ from './pages/FAQ';
import Events from './pages/Events';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Footer from './components/shared/footer';
import {Navigation} from './components/shared/navbar';
import NavbarContext from './components/shared/navbar-context';
import {PAGES} from './components/shared/navbar';

const App = () => {
  const [navbarContext, setNavbarContext] = useState(PAGES.HOME);
  return (
    <NavbarContext.Provider value={{navbarContext, setNavbarContext}}>
      <Router>
        <Navigation />
        <div id='main'>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="events" element={<Events />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="people" element={<People />} />
            </Route>
          </Routes>
        </div>
      </Router>
      <Footer />
    </NavbarContext.Provider>
  );
};

export default App;