import React from 'react'
import aboutBanner from '../assets/images/asset.about-background.jpg';
import {Banner} from '../components/shared/banner';
import {AboutContainer} from '../components/about/about-container';

const About = () => {
  return (
    <div>
      <Banner 
        image={aboutBanner}
        title='About us'
        color='#fff' />
      <AboutContainer />
    </div>
  )
}

export default About;
