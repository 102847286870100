import React from 'react'
import {importImages} from '../../functions/functions.shared';
import {SmallProfile} from '../../components/people/profiles';
import Bios from '../../assets/data/bios.json';

const nameArray = Object.keys(Bios);
const images = importImages(
  require.context('../../assets/images', false, /profile\..*\.(png|jpe?g)$/),
  ['profile.', /\.(jpe?g|png)/],
);

export const Department = (props) => {
  const {id, title} = props;
  return (
    <div id={id} className='padding-bottom'>
      <h1 className='text-center people-group-title'>{title}</h1>
      <div className='flex-center flex-wrap-wrap'>
        {nameArray
          .filter(key => Bios[key].department[0] === title && !Bios[key].isLeadership)
          .map((key, index) => {
            const name = Bios[key].name.replace(' ', '-').toLowerCase();
            return (
              <SmallProfile 
                key={index}
                name={name} 
                image={images[name]} />
            );
          })
        }
      </div>
    </div>
  )
}
