export const profileMapping = {
  ALLISON_NIXON: 'allison-nixon',
  ANI_GEORGE: 'ani-george',
  BEN_BARROW: 'ben-barrow',
  BROOKELYN_WATKINS: 'brookelyn-watkins',
  BRYSON_JAMES: 'bryson-james',
  COURTNEY_HUBENTHALL: 'courtney-hubenthall',
  DANIEL_DEVERAUX: 'daniel-deveraux',
  ELLA_SAMPSON: 'ella-sampson',
  ETHAN_GRASER: 'ethan-graser',
  GRANT_HOLDEN: 'grant-holden',
  HUNTER_BRANCH: 'hunter-branch',
  ISABEL_FRANDSEN: 'isabel-frandsen',
  JACOB_BARNES: 'jacob-barnes',
  JACOB_OLSEN: 'jacob-olsen',
  JAKE_WATKINS: 'jake-watkins',
  JONAH_WILSON: 'jonah-wilson',
  JORDYN_HANCOCK: 'jordyn-hancock',
  KATE_PERCIVAL: 'kate-percival',
  KATIE_ASHTON: 'katie-ashton',
  LEVI_PAYNTER: 'levi-paynter',
  MASON_CRAWFORD: 'mason-crawford',
  MCKENNA_BUTTERFIELD: 'mckenna-butterfield',
  MICHAEL_NIXON: 'michael-nixon',
  ROO_ROOKSTOOL: 'roo-rookstool',
  SETH_HARPER: 'seth-harper',
  SUSAN_OLDROYD: 'susan-oldroyd',
  TANNER_HILL: 'tanner-hill',
  TATE_WILKS: 'tate-wilks',
}