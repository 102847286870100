export const importImages = (requireContext, replaceValues) => {
  let images = {};
  requireContext.keys().forEach((item, index) => {
    let key = item.replace('./', '');
    for (let each of replaceValues) {
      key = key.replace(each, '');
    }
    images[key] = requireContext(item);
  });
  return images;
}