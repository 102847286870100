import React from 'react'
import '../../assets/styles/home/home.css';

export const LandingBanner = () => {
  return (
    <div id='landing-banner'>
      <div className='landing-text'>
        <h1>Adventure</h1>
        <h1>Connect</h1>
        <h1>Repeat</h1>
      </div>
    </div>
  )
}
