import '../../assets/styles/App.css';
import '../../assets/styles/landing.css'
import '../../assets/styles/shared/footer.css'
import {LazyLoadImage} from 'react-lazy-load-image-component';
import React from 'react';
import logoMain from '../../assets/images/asset.logo-black.png';

const SignUpContainer = (props) => {
  return(
    <div className='form-container flex-wrap my-4'>
      <h2 className='text-center'>Sign up for text and email alerts</h2>
      <div className='form-button'>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScUXs2J25yO5tDY6Q2M_NB08YSKFPjyDvVAWYhI9Kmgl0bdgg/viewform" target='_blank' className='title-theme text-bold txt-no-decor'>Sign Up</a>
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <footer>
      <div className="footer-flex py-4">
        <div className="footer-div">
          <div>
            <SignUpContainer />
          </div>
        </div>
        <div className="footer-div">
          <div>
            <div className='footer-img-container'>
              <LazyLoadImage src={logoMain} className="footer-img" alt="Logo image" />
            </div>
          </div>
        </div>
      </div>
      <div className='copy'>
        <p>{new Date().getFullYear()}&copy; Outdoor Adventure Crew</p>
      </div>
    </footer>
  )
}

export default Footer;