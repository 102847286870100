import React from 'react'
import {profileMapping} from '../assets/data/profile-constants';
import {importImages} from '../functions/functions.shared';
import {Banner} from '../components/shared/banner';
import {PeopleContainer} from '../components/people/people-container';
import peopleBanner from '../assets/images/asset.people-background.jpg';

import '../assets/styles/shared/container.css';
import '../assets/styles/shared/text.css';
import '../assets/styles/people/index.css';

const images = importImages(
  require.context('../assets/images', false, /profile\..*\.(png|jpe?g)$/),
  ['profile.', /\.(jpe?g|png)/],
);

const People = () => {
  return (
    <div>
      <Banner 
        image={peopleBanner} 
        title='People'
        color='#fff' />
      <PeopleContainer
        profileMapping={profileMapping}
        images={images} />
    </div>
  );
}

export default People;
