import React from 'react'
import eventsBanner from '../assets/images/asset.events-background.jpg';
import {Banner} from '../components/shared/banner';
import {EventsContainer} from '../components/events/events-container';

const Events = () => {
  return (
    <div>
      <Banner 
        image={eventsBanner}
        title='Events'
        color='#fff' />
      <EventsContainer />
    </div>
  )
}

export default Events;