import React from 'react'
import faqBanner from '../assets/images/asset.faq-background.jpg';
import {Banner} from '../components/shared/banner';
import {FaqContainer} from '../components/faq/faq-container';

const FAQ = () => {
  return (
    <div>
      <Banner 
        image={faqBanner}
        title='FAQ'
        color='#fff' />
      <FaqContainer />
    </div>
  )
}

export default FAQ;