import React, {useState, useEffect} from 'react';
import '../../assets/styles/events.css'
import prevArrow from '../../assets/images/asset.prev-arrow.svg';
import nextArrow from '../../assets/images/asset.next-arrow.svg';
import loadingCircle from '../../assets/images/asset.loader-spinning.png';
import {SingleEvent} from './single-event';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import $ from 'jquery';
import {API_ENDPOINT_URL_STR} from './googleApiConfig';

export const EventsContainer = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [events, setEvents] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const ajaxer = (params, successCallback, failureCallback) => {
    $.ajax({
      url: API_ENDPOINT_URL_STR,
      type: "POST",
      data: JSON.stringify(params),
      crossDomain: true,
      contentType: "application/json",
      dataType: "json",
      success: successCallback,
      error: failureCallback,
      timeout: 10000
    });
  };
    
  const handleFailure = (err) => {
    console.error(err);
    setError(`Oops! Something went wrong on our end. Please check back soon!`);
  };
    
  const handleSuccess = (data) => {
    if ('errorMessage' in data) {
      return handleFailure(data);
    }
    setError(null);
    setIsLoaded(true);
    setEvents(data);
  };
    
  const postRequest = () => {
    ajaxer(
      {year, month}, 
      handleSuccess, 
      handleFailure
    );
  };
  
  useEffect(postRequest, [year, month]);

  const getNextMonth = () => {
    const previousMonth = month;
    const nextMonth = (month % 12) + 1;
    setMonth(nextMonth);

    if (previousMonth > nextMonth) {
      setYear(year + 1);
    }

    setIsLoaded(false);
  };

  const getPreviousMonth = () => {
    const nextMonth = month;
    const previousMonth = (month - 1 < 1) ? 12 : month - 1;
    setMonth(previousMonth);

    if (nextMonth < previousMonth) {
      setYear(year - 1);
    }
    
    setIsLoaded(false);
  };

  let body;

  if (error) {
    body = (
      <div className='empty-event-div'>
        <p>{error}</p>
      </div>
    )
  } else if (!isLoaded) {
    body = (
      <div id='loading-div'>
        <div>
          <LazyLoadImage src={loadingCircle} className='loading' alt='loading' />
          <h2>Loading...</h2>
        </div>
      </div>
    )
  } else if (events.length === 0) {
    body = (
      <div className='empty-event-div'>
        <p>No events have been planned for {new Date(year, month-1, 1).toLocaleString('default', { month: 'long' })} yet. Please check back soon!</p>
      </div>
    );
  } else {
    body = (
      <div>
        {events.map((e, i) => (
          <SingleEvent
            title={e.title}
            description={e.description}
            dates={{
              startDay: e.startDay, 
              startDayLong: new Date(year, month, e.startDay).toLocaleDateString('default', { weekday: 'long' }),
              endDay: e.endDay, 
              endDayLong: new Date(year, month, e.endDay).toLocaleDateString('default', { weekday: 'long' }),
              startTime: e.startTime, 
              endTime: e.endTime,
              startMonth: e.startMonth,
              endMonth: e.endMonth
            }}
            key={i}
          />
        ))}
      </div>
    );
  }

  return(
    <div className='d-flex justify-center'>
      <div id='event-container'>
        <div className='d-flex center-v py-3' id='cal-nav'>
          <div className='d-flex justify-between width-100p'>
            <div>
              <button 
                onClick={getPreviousMonth} 
                className='arrow-btn'>
                <LazyLoadImage src={prevArrow} />
              </button>
            </div>
            <div>
              <h3>{new Date(year, month-1, 1).toLocaleString('default', { month: 'long' })} {year}</h3>
            </div>
            <div>
              <button 
                onClick={getNextMonth} 
                className='arrow-btn'>
                <LazyLoadImage src={nextArrow} />
              </button>
            </div>
          </div>
        </div>
        <div id='events'>
          {body}
        </div>
      </div>
    </div>
  );
};