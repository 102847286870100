import React, {useContext} from 'react'
import {Link} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import homeIcon from '../../assets/images/asset.home-menu-icon.svg';
import aboutIcon from '../../assets/images/asset.about-menu-icon.svg';
import eventsIcon from '../../assets/images/asset.events-menu-icon.svg';
import faqIcon from '../../assets/images/asset.faq-menu-icon.svg';
import peopleIcon from '../../assets/images/asset.people-menu-icon.svg';
import '../../assets/styles/shared/navbar.css'
import NavbarContext from './navbar-context';

export const PAGES = {
  HOME: 'home',
  ABOUT: 'about',
  EVENTS: 'events',
  FAQ: 'faq',
  PEOPLE: 'people',
}

export const Navigation = () => {
  const {navbarContext, setNavbarContext} = useContext(NavbarContext);

  return (
    <div id='navbar'>
      <div id='top-nav-home' className='menu-option-container'>
        <Link 
          to='/' 
          onClick={() => setNavbarContext(PAGES.HOME)} 
        >
          <h1 className={navbarContext === PAGES.HOME ? 'desktop active' : 'desktop'}>Home</h1>
          <div className='menu-icon-container'>
            <LazyLoadImage className='mobile menu-icon' src={homeIcon} />
          </div>
        </Link>
      </div>
      <div id='top-nav-about' className='menu-option-container'>
        <Link 
            to='/about' 
            onClick={() => setNavbarContext(PAGES.ABOUT)} 
          >
          <h1 className={navbarContext === PAGES.ABOUT ? 'desktop active' : 'desktop'}>About</h1>
          <div className='menu-icon-container'>
            <LazyLoadImage className='mobile menu-icon' src={aboutIcon} />
          </div>
        </Link>
      </div>
      <div id='top-nav-events' className='menu-option-container'>
        <Link 
            to='/events' 
            onClick={() => setNavbarContext(PAGES.EVENTS)} 
          >
          <h1 className={navbarContext === PAGES.EVENTS ? 'desktop active' : 'desktop'}>Events</h1>
          <div className='menu-icon-container'>
            <LazyLoadImage className='mobile menu-icon' src={eventsIcon} />
          </div>
        </Link>
      </div>
      <div id='top-nav-faq' className='menu-option-container'>
        <Link 
            to='/faq' 
            onClick={() => setNavbarContext(PAGES.FAQ)} 
          >
          <h1 className={navbarContext === PAGES.FAQ ? 'desktop active' : 'desktop'}>FAQ</h1>
          <div className='menu-icon-container'>
            <LazyLoadImage className='mobile menu-icon' src={faqIcon} />
          </div>
        </Link>
      </div>
      <div id='top-nav-people' className='menu-option-container'>
        <Link 
            to='/people' 
            onClick={() => setNavbarContext(PAGES.PEOPLE)} 
          >
          <h1 className={navbarContext === PAGES.PEOPLE ? 'desktop active' : 'desktop'}>People</h1>
          <div className='menu-icon-container'>
            <LazyLoadImage className='mobile menu-icon' src={peopleIcon} />
          </div>
        </Link>
      </div>
    </div>
  )
}
