import React from 'react'
const Bios = require('../../assets/data/bios.json');
import '../../assets/styles/people/profiles.css';
import '../../assets/styles/people/index.css';
import {LazyLoadImage} from 'react-lazy-load-image-component';

export const LargeProfile = ({name, image}) => {
  const data = Bios[name];
  return (
    <div className='profile-large'>
      <div className='flex-center'>
        <div className='profile-large-img-container flex-center'>
          <LazyLoadImage src={image} className='profile-large-img' />
        </div>
      </div>
      <div>
        <div className='profile-large-data'>
          <h1>{data.name}</h1>
          <h2>{data.title}</h2>
        </div>
      </div>
    </div>
  )
}

export const SmallProfile = ({name, image}) => {
  const data = Bios[name];
  return (
    <div className='profile-small'>
      <div className='profile-small-container flex-center'>
        <div className='flex-center align-center'>
          <div className='profile-small-img-container flex-center'>
            <LazyLoadImage src={image} className='profile-small-img' />
          </div>
        </div>
        <div className='profile-small-data-container'>
          <div className='profile-small-data'>
            <h1>{data.name}</h1>
            <h2>{data.title}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
