import React from 'react'
import {LandingBanner} from '../components/home/landing-banner';
import {CalendarSection} from '../components/home/calendar-section';
import {SelectionBoxes} from '../components/home/selection-boxes';
import {SocialMediaSection} from '../components/home/social-media-section';

const Home = () => {
  return (
    <div id='home'>
      <LandingBanner />
      <CalendarSection />
      <SelectionBoxes />
      <SocialMediaSection />
    </div>
  )
}

export default Home;