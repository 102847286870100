import React, {useState} from 'react';
import {QaBox} from './qa-box';
import '../../assets/styles/faq/faq.css';
const faqDataJson = require('../../assets/data/faq.json');

export const FaqContainer = () => {
  return (
    <div>
      <div>
        <h1 className='text-center p-4'>
          We get these questions occasionally
        </h1>
      </div>
      <div className='d-flex justify-center'>
        <div id='faq-container'>
          {faqDataJson.map((data, index) => {
            return <QaBox 
              key={index}
              question={data.question}
              answers={data.answer}
              />
          })}
        </div>
      </div>
    </div>
  )
}
