import React, { useContext } from 'react'
import {Link} from 'react-router-dom'
import {LazyLoadImage} from 'react-lazy-load-image-component';
import aboutBanner from '../../assets/images/asset.about-background.jpg';
import eventsBanner from '../../assets/images/asset.events-background.jpg';
import faqBanner from '../../assets/images/asset.faq-background.jpg';
import peopleBanner from '../../assets/images/asset.people-background.jpg';
import '../../assets/styles/home/home.css';
import NavbarContext from '../shared/navbar-context';
import {PAGES} from '../shared/navbar';

const Box = ({id, image}) => {
  const navbarContext = useContext(NavbarContext);
  const setActivePage = (page) => {
    navbarContext.activePage = PAGES[page.toUpperCase()];
  }

  return (
    <div id={`${id}-box-selection`} className='box-selection-container' onClick={() => setActivePage(id)}>
      <Link to={`/${id}`} className='box-link d-flex center-v justify-center'>
        <div className='box-selection-text'>
          <h1>{id.toUpperCase()}</h1>
        </div>
        <LazyLoadImage src={image} className='box-selection-image'/>
      </Link>
    </div>
  )
}

export const SelectionBoxes = () => {
  const selections = [
    {id: 'about', image: aboutBanner},
    {id: 'events', image: eventsBanner},
    {id: 'faq', image: faqBanner},
    {id: 'people', image: peopleBanner},
  ];

  return (
    <div id='selection-boxes'>
      <div id='selection-boxes-container'>
        {selections.map((selection, index) => <Box key={index} id={selection.id} image={selection.image} />)}
      </div>  
    </div>
  )
}
