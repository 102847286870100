import React from 'react'
import '../../assets/styles/about/about.css';
const textData = require('../../assets/data/textdata.json');

export const AboutContainer = () => {
  return (
    <div id='about'>
      <div id='about-the-crew' className='pb-4 about-container'>
        <h1 className='title-theme'>What is the Crew?</h1>
        {textData.aboutText.map((text, index) => <p className='py-4' key={index}>{text}</p>)}
      </div>
      <div id='about-the-mission' className='pb-4 about-container'>
        <h1 className='title-theme'>What is our Mission?</h1>
        {textData.missionText.map((text, index) => <p className='py-4' key={index}>{text}</p>)}
      </div>
      <div id='about-the-leadership' className='pb-4 about-container'>
        <h1 className='title-theme'>Who leads the Crew?</h1>
        {textData.leadershipText.map((text, index) => <p className='py-4' key={index}>{text}</p>)}
      </div>
    </div>
  )
}
