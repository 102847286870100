import React from 'react'
import {LargeProfile} from './profiles'
import {Department} from './department'

export const PeopleContainer = ({profileMapping, images}) => {
  return (
    <div className='d-flex justify-center'>
      <div id='people-container'>
        <div id='directors' className='padding-bottom'>
          <h1 className='text-center people-group-title'>Board of Directors</h1>
          <div className='flex-center flex-wrap-wrap'>
            <LargeProfile 
              name={profileMapping.MICHAEL_NIXON} 
              image={images[profileMapping.MICHAEL_NIXON]} />
            <LargeProfile 
              name={profileMapping.ALLISON_NIXON} 
              image={images[profileMapping.ALLISON_NIXON]} />
            <LargeProfile 
              name={profileMapping.JACOB_BARNES} 
              image={images[profileMapping.JACOB_BARNES]} />
          </div>
        </div>
        <div id='coordinators' className='padding-bottom'>
          <h1 className='text-center people-group-title'>Coordinators</h1>
          <div className='flex-center flex-wrap-wrap'>
            <LargeProfile 
              name={profileMapping.SUSAN_OLDROYD}
              image={images[profileMapping.SUSAN_OLDROYD]} />
            <LargeProfile 
              name={profileMapping.SETH_HARPER}
              image={images[profileMapping.SETH_HARPER]} />
            <LargeProfile 
              name={profileMapping.JORDYN_HANCOCK}
              image={images[profileMapping.JORDYN_HANCOCK]} />
            <LargeProfile 
              name={profileMapping.ETHAN_GRASER}
              image={images[profileMapping.ETHAN_GRASER]} />
          </div>
        </div>
        <Department 
          id='org' 
          title='Organization' />
        <Department 
          id='trips' 
          title='Trip Planning' />
        <Department 
          id='marketing-comms' 
          title='Marketing & Communications' />
        <Department 
          id='food-gear' 
          title='Food, Gear, & Assets' />
        <Department 
          id='hikes' 
          title='Hike & Hangs' />
      </div>
    </div>
  )
}
